import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { useUpdateCart } from "../../hooks/useManageCart";
import isNumeric from "../../utils/isNumeric";
import { useQueryClient } from "react-query";
import css from "./QuantityList.module.css";
import { getHasImpersonate, checkPermission } from "../../utils/jwt";
import { PERMISSSIONS } from "../checkout/Constants";


function QuantityList({ item }) {
  const [quantity, setQuantity] = useState(item?.quantity ? item?.quantity : 0);
  const mutation = useUpdateCart();
  const queryClient = useQueryClient();

  const hasImpersonate = getHasImpersonate();
  const hasEditPerm = checkPermission(PERMISSSIONS.EDIT_ORDER_ITEM);
  const disableEdit = hasImpersonate && !hasEditPerm;


  useEffect(() => {
    if (isNumeric(item?.quantity)) {
      setQuantity(item?.quantity);
    }
  }, [item?.quantity]);

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      handleMutate(quantity - 1);
    }
  };

  const handleIncrement = () => {
    if (quantity < item?.inventory) {
      setQuantity(quantity + 1);
      handleMutate(quantity + 1);
    }
  };

  const handleMutate = (newQty) => {
    mutation.mutate({
      items: [
        {
          sku: item?.sku,
          quantity: newQty,
        },
      ],
      rewardPoints: 0,
      couponCode: "",
      shipVia: "",
      shippingRate: "",
      shipName: "",
      discount: 0,
      isPercent: false,
    });
    queryClient.invalidateQueries("shippingOption");
  };

  const handleQtyChange = (evt) => {
    let newQty = Number(evt?.target?.value?.replace(/[^\d]/g, ""));
    newQty = newQty < 1 ? 1 : newQty;
    if (newQty === quantity) {
      return;
    }
    newQty = newQty > item?.inventory ? item.inventory : newQty;
    setQuantity(newQty);
    handleMutate(newQty);
  };

  const isFreeItem = item?.isFree;
  const decrementDisabled = isFreeItem || quantity === 1;
  const incrementDisabled = isFreeItem || (quantity === item?.inventory);
  const quantityDisabled = isFreeItem;

  return (
    <div className={css["quantity-list"]}>
      <IconButton
        aria-label="decrement"
        sx={iconStyle}
        onClick={handleDecrement}
        disabled={decrementDisabled || disableEdit}
      >
        <RemoveIcon fontSize="small" />
      </IconButton>
      <TextField
        variant="outlined"
        size="small"
        value={quantity}
        inputProps={{ style: { textAlign: "center" } }}
        InputProps={{ sx: { height: {xs: "1.8rem", sm: "2rem"} } }}
        onChange={handleQtyChange}
        id={`product-quantity-${item?.sku}`}
        disabled={quantityDisabled || disableEdit}
      ></TextField>
      <IconButton
        aria-label="decrement"
        sx={iconStyle}
        disabled={incrementDisabled || disableEdit}
        onClick={handleIncrement}
      >
        <AddIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

const iconStyle = {
  color: "var(--dark-blue)",
  minWidth: "unset",
  height: "auto",
  margin: "1px",
  padding: "4px",
  "&:hover": {
    color: "var(--white)",
    backgroundColor: "var(--blue-400)",
  },
};

QuantityList.propTypes = {
  item: PropTypes.object,
};

export default QuantityList;
